import * as I18nJs from 'i18n-js'
import translations from './translations.json'

const I18n = new I18nJs.I18n(translations)
I18n.defaultLocale = 'lv'
I18n.locale = document.documentElement.getAttribute('lang')
I18n.fallbacks = true

export default I18n
export const t = I18n.t.bind(I18n)
export const l = I18n.l.bind(I18n)
export const locale = I18n.locale
